import React, { Component } from 'react'
import styled, {keyframes} from 'styled-components'

const shake = keyframes`
  10%, 90% { transform: rotate(30deg); }
  20%, 80% { transform: rotate(135deg); }
  30%, 50%, 70% { transform: rotate(125deg); }
  40%, 60% { transform: roatate(45deg); }
`

const DiceButton = styled.button`
  animation-name: ${props => (props.rolling && !props.isLocked) ? shake : 'none'};
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-delay: ${props => props.animationDelay};
`

/**
 * DICE EYE COMPONENTS
 */
const Dice1 = function(props) {
  return (
    <g>
      <path d="M34.9766,30.0537 C37.4616,30.0537 39.4766,32.0687 39.4766,34.5537 C39.4766,37.0387 37.4616,39.0537 34.9766,39.0537 C32.4916,39.0537 30.4766,37.0387 30.4766,34.5537 C30.4766,32.0687 32.4916,30.0537 34.9766,30.0537" fill={props.fill}></path>
    </g>
  );
}
const Dice2 = function(props) {
  return (
    <g>
      <path d="M26.9766,47.0537 C24.4916,47.0537 22.4766,45.0387 22.4766,42.5537 C22.4766,40.0687 24.4916,38.0537 26.9766,38.0537 C29.4616,38.0537 31.4766,40.0687 31.4766,42.5537 C31.4766,45.0387 29.4616,47.0537 26.9766,47.0537" fill={props.fill}></path>
      <path d="M42.9766,23.0537 C45.4616,23.0537 47.4766,25.0687 47.4766,27.5537 C47.4766,30.0387 45.4616,32.0537 42.9766,32.0537 C40.4916,32.0537 38.4766,30.0387 38.4766,27.5537 C38.4766,25.0687 40.4916,23.0537 42.9766,23.0537" fill={props.fill}></path>
    </g>
  )
}

const Dice3 = function(props) {
  return (
    <g>
      <path d="M22.9766,51.0537 C20.4916,51.0537 18.4766,49.0387 18.4766,46.5537 C18.4766,44.0687 20.4916,42.0537 22.9766,42.0537 C25.4616,42.0537 27.4766,44.0687 27.4766,46.5537 C27.4766,49.0387 25.4616,51.0537 22.9766,51.0537" fill={props.fill}></path>
      <path d="M34.9766,30.0537 C37.4616,30.0537 39.4766,32.0687 39.4766,34.5537 C39.4766,37.0387 37.4616,39.0537 34.9766,39.0537 C32.4916,39.0537 30.4766,37.0387 30.4766,34.5537 C30.4766,32.0687 32.4916,30.0537 34.9766,30.0537" fill={props.fill}></path>
      <path d="M46.9766,19.0537 C49.4616,19.0537 51.4766,21.0687 51.4766,23.5537 C51.4766,26.0387 49.4616,28.0537 46.9766,28.0537 C44.4916,28.0537 42.4766,26.0387 42.4766,23.5537 C42.4766,21.0687 44.4916,19.0537 46.9766,19.0537" fill={props.fill}></path>
    </g>
  )
}

const Dice4 = function(props) {
  return (
    <g>
      <path d="M22.9766,19.0537 C25.4616,19.0537 27.4766,21.0687 27.4766,23.5537 C27.4766,26.0387 25.4616,28.0537 22.9766,28.0537 C20.4916,28.0537 18.4766,26.0387 18.4766,23.5537 C18.4766,21.0687 20.4916,19.0537 22.9766,19.0537" fill={props.fill}></path>
      <path d="M46.9766,19.0537 C49.4616,19.0537 51.4766,21.0687 51.4766,23.5537 C51.4766,26.0387 49.4616,28.0537 46.9766,28.0537 C44.4916,28.0537 42.4766,26.0387 42.4766,23.5537 C42.4766,21.0687 44.4916,19.0537 46.9766,19.0537" fill={props.fill}></path>
      <path d="M22.9766,42.0537 C25.4616,42.0537 27.4766,44.0687 27.4766,46.5537 C27.4766,49.0387 25.4616,51.0537 22.9766,51.0537 C20.4916,51.0537 18.4766,49.0387 18.4766,46.5537 C18.4766,44.0687 20.4916,42.0537 22.9766,42.0537" fill={props.fill}></path>
      <path d="M46.9766,42.0537 C49.4616,42.0537 51.4766,44.0687 51.4766,46.5537 C51.4766,49.0387 49.4616,51.0537 46.9766,51.0537 C44.4916,51.0537 42.4766,49.0387 42.4766,46.5537 C42.4766,44.0687 44.4916,42.0537 46.9766,42.0537" fill={props.fill}></path>
    </g>
  )
}

const Dice5 = function(props) {
  return (
    <g>
      <path d="M22.9766,19.0537 C25.4616,19.0537 27.4766,21.0687 27.4766,23.5537 C27.4766,26.0387 25.4616,28.0537 22.9766,28.0537 C20.4916,28.0537 18.4766,26.0387 18.4766,23.5537 C18.4766,21.0687 20.4916,19.0537 22.9766,19.0537" fill={props.fill}></path>
      <path d="M34.9766,30.0537 C37.4616,30.0537 39.4766,32.0687 39.4766,34.5537 C39.4766,37.0387 37.4616,39.0537 34.9766,39.0537 C32.4916,39.0537 30.4766,37.0387 30.4766,34.5537 C30.4766,32.0687 32.4916,30.0537 34.9766,30.0537" fill={props.fill}></path>
      <path d="M46.9766,19.0537 C49.4616,19.0537 51.4766,21.0687 51.4766,23.5537 C51.4766,26.0387 49.4616,28.0537 46.9766,28.0537 C44.4916,28.0537 42.4766,26.0387 42.4766,23.5537 C42.4766,21.0687 44.4916,19.0537 46.9766,19.0537" fill={props.fill}></path>
      <path d="M22.9766,42.0537 C25.4616,42.0537 27.4766,44.0687 27.4766,46.5537 C27.4766,49.0387 25.4616,51.0537 22.9766,51.0537 C20.4916,51.0537 18.4766,49.0387 18.4766,46.5537 C18.4766,44.0687 20.4916,42.0537 22.9766,42.0537" fill={props.fill}></path>
      <path d="M46.9766,42.0537 C49.4616,42.0537 51.4766,44.0687 51.4766,46.5537 C51.4766,49.0387 49.4616,51.0537 46.9766,51.0537 C44.4916,51.0537 42.4766,49.0387 42.4766,46.5537 C42.4766,44.0687 44.4916,42.0537 46.9766,42.0537" fill={props.fill}></path>
    </g>
  )
}

const Dice6 = function(props) {
  return (
    <g>
      <path d="M22.9766,55.0537 C20.4916,55.0537 18.4766,53.0387 18.4766,50.5537 C18.4766,48.0687 20.4916,46.0537 22.9766,46.0537 C25.4616,46.0537 27.4766,48.0687 27.4766,50.5537 C27.4766,53.0387 25.4616,55.0537 22.9766,55.0537"  fill={props.fill}></path>
      <path d="M22.9766,30.0537 C25.4616,30.0537 27.4766,32.0687 27.4766,34.5537 C27.4766,37.0387 25.4616,39.0537 22.9766,39.0537 C20.4916,39.0537 18.4766,37.0387 18.4766,34.5537 C18.4766,32.0687 20.4916,30.0537 22.9766,30.0537"  fill={props.fill}></path>
      <path d="M22.9766,15.0537 C25.4616,15.0537 27.4766,17.0687 27.4766,19.5537 C27.4766,22.0387 25.4616,24.0537 22.9766,24.0537 C20.4916,24.0537 18.4766,22.0387 18.4766,19.5537 C18.4766,17.0687 20.4916,15.0537 22.9766,15.0537"  fill={props.fill}></path>
      <path d="M46.9766,55.0537 C44.4916,55.0537 42.4766,53.0387 42.4766,50.5537 C42.4766,48.0687 44.4916,46.0537 46.9766,46.0537 C49.4616,46.0537 51.4766,48.0687 51.4766,50.5537 C51.4766,53.0387 49.4616,55.0537 46.9766,55.0537"  fill={props.fill}></path>
      <path d="M46.9766,30.0537 C49.4616,30.0537 51.4766,32.0687 51.4766,34.5537 C51.4766,37.0387 49.4616,39.0537 46.9766,39.0537 C44.4916,39.0537 42.4766,37.0387 42.4766,34.5537 C42.4766,32.0687 44.4916,30.0537 46.9766,30.0537"  fill={props.fill}></path>
      <path d="M46.9766,15.0537 C49.4616,15.0537 51.4766,17.0687 51.4766,19.5537 C51.4766,22.0387 49.4616,24.0537 46.9766,24.0537 C44.4916,24.0537 42.4766,22.0387 42.4766,19.5537 C42.4766,17.0687 44.4916,15.0537 46.9766,15.0537"  fill={props.fill}></path>
    </g>
  )
}



class Dice extends Component {

    componentDidMount() {
      this.animationDelay = (-1) * (Math.floor(Math.random()*700) + 300) + 'ms';
    }

    diceEyeComponents = {
      dice1: Dice1,
      dice2: Dice2,
      dice3: Dice3,
      dice4: Dice4,
      dice5: Dice5,
      dice6: Dice6
    }

    diceImageUrl() {
      const isLocked = this.props.isLocked;
      const bgPath = isLocked ? (
        <path d="M63.7136,70 L6.2856,70 C2.8196,70 -0.0004,67.18 -0.0004,63.714 L-0.0004,6.285 C-0.0004,2.819 2.8196,0 6.2856,0 L63.7136,0 C67.1806,0 69.9996,2.819 69.9996,6.285 L69.9996,63.714 C69.9996,67.18 67.1806,70 63.7136,70 Z" id="Fill-5" fill="#000000"></path>
      ) : (
        <path d="M6.2856,3 C4.4736,3 2.9996,4.474 2.9996,6.285 L2.9996,63.714 C2.9996,65.525 4.4736,67 6.2856,67 L63.7136,67 C65.5256,67 66.9996,65.525 66.9996,63.714 L66.9996,6.285 C66.9996,4.474 65.5256,3 63.7136,3 L6.2856,3 Z M63.7136,70 L6.2856,70 C2.8196,70 -0.0004,67.18 -0.0004,63.714 L-0.0004,6.285 C-0.0004,2.819 2.8196,0 6.2856,0 L63.7136,0 C67.1806,0 69.9996,2.819 69.9996,6.285 L69.9996,63.714 C69.9996,67.18 67.1806,70 63.7136,70 Z" fill="#000000"></path>
      );
      const fillEye = isLocked ? '#FFDADD' : '#000000';


      var DiceEyes = this.diceEyeComponents["dice" + parseInt(this.props.nr)];

      return (
        <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g>
            {bgPath}
            <DiceEyes fill={fillEye} />
          </g>
        </svg>
      );
    }

    render() {
        return (
          <DiceButton
            className="dice"
            isLocked={this.props.isLocked}
            onClick={this.props.onClick}
            rolling={this.props.rolling}
            disabled={this.props.rolling}
            animationDelay={this.animationDelay}
          >
            {this.diceImageUrl()}
          </DiceButton>
        )
    }
}

export default Dice
