// export const LANDING = '/';
// export const SIGN_UP = '/signup';
// export const SIGN_IN = '/signin';
// export const HOME = '/home';
// export const ACCOUNT = '/account';
// export const ADMIN = '/admin';
// export const PASSWORD_FORGET = '/pw-forget';

export const WELCOME = '/';
export const ONLINE = '/online';
export const OFFLINE = '/offline';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const GAME = '/game';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
